import { socket } from "@/socket";
import { defineStore } from "pinia";


export const useConnectionStore = defineStore("ConnectionStore", {
  state: () => ({
    isConnected: false,
  }),

  actions: {
    bindEvents() {
      socket.on("connect", () => {
        console.log('useConnectionStore: connect')
        this.isConnected = true;
      });

      socket.on("disconnect", () => {
        console.log('useConnectionStore: disconnect')
        this.isConnected = false;
      });
    },

    connect() {
      socket.connect();
    },
    disconnect() {
      socket.off();
    }
  },
});
