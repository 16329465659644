<template>
  <button
    :disabled="isDisabled"
    :class="[
      bg.light,
      border.light,
      text.light,
      hover.light,
      bg.dark,
      border.dark,
      text.dark,
      hover.dark,
      '',
    ]"
    :tabindex="tabIndex"
  >
    <slot>Button Text</slot>
    <span :class="isRequestLoading && 'loader'" />
  </button>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
export default defineComponent({
  name: 'IButton',
  props: {
    action: { type: String, default: '' },
    tabIndex: { type: Number, required: true },
    isRequestLoading: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
  },
  setup: (props) => {
    // Define needed variables.
    const style = ref({
      bg: { light: '', dark: '' },
      text: { light: '', dark: '' },
      border: { light: '', dark: '' },
      hover: { light: '', dark: '' },
    })
    // Define styles for button.
    switch (props.action) {
      case 'success':
        style.value = {
          bg: { light: '', dark: '' },
          text: { light: '', dark: '' },
          border: { light: '', dark: '' },
          hover: {
            light: '',
            dark: '',
          },
        }
        break
      case 'info':
        style.value = {
          bg: { light: 'bg-blue-500', dark: '' },
          text: { light: '', dark: '' },
          border: { light: 'border-blue-700', dark: 'dark:border-blue' },
          hover: {
            light: '',
            dark: '',
          },
        }
        break
      case 'warning':
        style.value = {
          bg: { light: 'bg-yellow-500', dark: '' },
          text: { light: '', dark: '' },
          border: { light: 'border-yellow-700', dark: 'dark:border-yellow' },
          hover: {
            light: '',
            dark: 'dark:hover:bg-yellow dark:hover:text-secondary-light dark:hover:border-transparent',
          },
        }
        break
      case 'error':
        style.value = {
          bg: { light: 'bg-red-500', dark: '' },
          text: { light: '', dark: '' },
          border: { light: 'border-red-700', dark: 'dark:border-red' },
          hover: {
            light: '',
            dark: 'dark:hover:bg-red dark:hover:text-secondary-light dark:hover:border-transparent',
          },
        }
        break
      default:
        style.value = {
          bg: { light: 'bg-secondary', dark: '' },
          text: { light: 'text-secondary-dark', dark: '' },
          border: {
            light: 'border-secondary-dark',
            dark: 'dark:border-secondary',
          },
          hover: {
            light:
              'hover:bg-secondary-dark hover:text-secondary hover:border-transparent',
            dark: 'dark:hover:bg-secondary dark:hover:text-secondary-darker dark:hover:border-transparent',
          },
        }
        break
    }
    // Return variables.
    return {
      bg: style.value.bg,
      border: style.value.border,
      text: style.value.text,
      hover: style.value.hover,
    }
  },
})
</script>
