<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <template
            v-for="{ key, label, sortable } in fields"
            :key="key"
          >
            <th
              v-if="sortable"
              class="sortable"
              @click="setSort(key)"
            >
              {{ label }}
              <template v-if="sortBy === key">
                <span v-if="sortDesc === true">↑</span>
                <span v-else-if="sortDesc === false">↓</span>
              </template>
            </th>
            <th v-else>
              {{ label }}
            </th>
          </template>
          <th />
        </tr>
      </thead>

      <tbody>
        <template
          v-for="item in paginatedItems"
          :key="item.id"
        >
          <tr>
            <td
              v-for="{ key } in fields"
              :key="key"
            >
              <slot
                :name="`cell(${key})`"
                :value="item[key]"
                :item="item"
              >
                {{ item[key] }}
              </slot>
            </td>
            <td>
              <slot
                name="profile-link"
                :item="item"
              />
            </td>
          </tr>
          <tr>
            <td colspan="100">
              <slot
                name="portfolio-images"
                :item="item"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>

  <div class="text-right">
    <button
      class="btn btn-icon"
      :disabled="pagination.currentPage <= 1"
      @click="pagination.currentPage--"
    >
      ←
    </button>
    Page {{ pagination.currentPage }} of {{ pagination.totalPages }}
    <button
      class="btn btn-icon"
      :disabled="pagination.currentPage >= pagination.totalPages"
      @click="pagination.currentPage++"
    >
      →
    </button>
  </div>
</template>

<script setup>
import { sort } from 'fast-sort';
import { computed, reactive, ref, watch } from 'vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  fields: {
    type: Array,
    required: true,
  },
})

const pagination = reactive({
  currentPage: 1,
  perPage: 20,
  totalPages: computed(() =>
    Math.ceil(props.items.length / pagination.perPage)
  ),
})

const sortBy = ref(null)
const sortDesc = ref(null)

const sortedItems = computed(() => {
  const { items } = props
  if (sortDesc.value === null) return items

  if (sortDesc.value) {
    return sort(items).desc(sortBy.value)
  }
  return sort(items).asc(sortBy.value)
})

const setSort = (key) => {
  if (sortBy.value === key) {
    if (sortDesc.value === true) sortDesc.value = null
    else if (sortDesc.value === false) sortDesc.value = true
    else sortDesc.value = false
  } else {
    sortBy.value = key
    sortDesc.value = false
  }
}

const paginatedItems = computed(() => {
  const { currentPage, perPage } = pagination
  const start = (currentPage - 1) * perPage
  const stop = start + perPage

  return sortedItems.value.slice(start, stop)
})

watch(
  () => pagination.totalPages,
  () => {
    if (pagination.currentPage > pagination.totalPages) {
      pagination.currentPage = pagination.totalPages
    }
  }
)
</script>

<style>
.table-responsive {
  border: 1px solid black;
}

.table {
  width: 100%;
}

.table > thead > tr {
  border-bottom: 1px solid black;
}

.table > tbody > tr:nth-child(odd) {
  /* background-color: rgba(0, 0, 0, 0.2); */
}

th.sortable {
  cursor: pointer;
}
</style>
