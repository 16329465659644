<template>
  <div v-if="dialog.state.active">
    <div class="dialog-inner">
      <h3 v-if="dialog.state.title">
        {{ dialog.state.title }}
      </h3>

      <p
        v-if="dialog.state.html"
        v-html="dialog.state.message"
      />
      <p v-else>
        {{ dialog.state.message }}
      </p>

      <input
        v-if="dialog.state.type === 'prompt'"
        v-model="userInput"
        :type="dialog.state.inputType"
      >

      <div class="d-flex column-gap-3">
        <button
          v-if="dialog.state.type !== 'alert'"
          class="btn btn-secondary my-2"
          tabindex="1"
          @click="dialog.cancel()"
        >
          {{ dialog.state.cancelText }}
        </button>
        <button
          class="btn btn-secondary my-2"
          tabindex="2"
          @click="dialog.ok(userInput)"
        >
          {{ dialog.state.okText }}
        </button>
      </div>
    </div>
    <div
      class="dialog-bg"
      @click="dialog.cancel()"
    />
  </div>
</template>

<script>
import { dialog } from '@/helpers'
export default {
  name: 'PopUp',
  data() {
    return {
      userInput: '',
      dialog,
    }
  },
}
</script>

<style>
.dialog-bg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.dialog-inner {
  background: white;
  z-index: 2;
  padding: 20px;
  position: fixed;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  min-width: 200px;
  z-index: 1001;
}
</style>
