<template>
  <form
    class="form"
    @submit.prevent="register"
  >
    <div class="my-3">
      <ISelect
        :id="'selected_gender'"
        v-model="selected_gender"
        :select-options="['Mr.', 'Mrs']"
        :label="'Please select*'"
        :placeholder="'Please select*'"
        :help-text="''"
        :tab-index="1"
        :is-required="true"
      />
    </div>
    <div class="my-3">
      <IInput
        :id="'first_name'"
        v-model.trim="first_name"
        :label="'First name'"
        :input-type="'text'"
        :placeholder="'Enter your first name'"
        :help-text="''"
        :tab-index="2"
        :is-required="true"
      />
    </div>
    <div class="my-3">
      <IInput
        :id="'last_name'"
        v-model.trim="last_name"
        :label="'Last name'"
        :input-type="'text'"
        :placeholder="'Enter your last name'"
        :help-text="''"
        :tab-index="3"
        :is-required="true"
      />
    </div>
    <div class="my-3">
      <IInput
        :id="'email'"
        v-model.trim="email"
        :label="'Email'"
        :input-type="'email'"
        :placeholder="'Enter your email address'"
        :help-text="'Only a valid email, we\'ll send a confirmation email to it.'"
        :tab-index="4"
        :is-required="true"
        :klass="[
          getError &&
            (getError.input_name === 'email' ||
              getError.input_name === undefined) &&
            getError.status,
        ]"
        @focusout="verifyEmail"
      />
    </div>
    <div class="my-3">
      <IInput
        :id="'password'"
        v-model.trim="password"
        :label="'Password'"
        :input-type="'password'"
        :placeholder="'Enter your secret password'"
        :help-text="`
            Your new password... <br>
                   <span class=${
          containOneDigit && 'password_pattern_validation'
        }> 1. must contain digit [0-9]
                   ${
          password
            ? containOneDigit
              ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
              : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
            : ''
        }<br /></span>
        <span
        class=${oneLowercaseCharacter && 'password_pattern_validation'}
          >2. One lowercase character [a-z] ${
          password
            ? oneLowercaseCharacter
              ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
              : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
            : ''
        }<br
        /></span>
        <span
        class=${oneUppercaseCharacter && 'password_pattern_validation'}
          >3. One uppercase character [A-Z] ${
          password
            ? oneUppercaseCharacter
              ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
              : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
            : ''
        }<br
        /></span>
        <span class=${
          oneSymbol && 'password_pattern_validation'
        }>4. One symbol [@#$%] ${
          password
            ? oneSymbol
              ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
              : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
            : ''
        }<br /></span>
        <span
        class=${lengthISGreaterThen && 'password_pattern_validation'}
          >5. Length of password between [5-20].${
          password
            ? lengthISGreaterThen
              ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
              : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
            : ''
        }</span
        >
        `"
        :tab-index="5"
        :is-required="true"
        :klass="[
          'password',
          getError && getError.input_name == 'password' && getError.status,
        ]"
      />
    </div>
    <div
      v-if="showPasswordConfirmation"
      class="my-3 input-group"
    >
      <IInput
        :id="'password_confirmation'"
        v-model.trim="password_confirmation"
        :label="'password_confirmation'"
        :input-type="'password'"
        :placeholder="'Enter password confirmation'"
        :tab-index="6"
        :is-required="true"
        :klass="[
          'password_confirmation',
          getError &&
            getError.input_name == 'password_confirmation' &&
            getError.status,
        ]"
        @focusout="verifyPasswordConfirmation"
      />
    </div>
    <div class="my-4 font-wht">
      <Checkbox
        :id="'terms_and_tonditions'"
        v-model="terms_and_tonditions"
        :label="'I agree to terms and conditions.'"
        :input-type="'checkbox'"
        :tab-index="7"
        :name="'terms_and_tonditions'"
        :is-required="true"
        :klass="[
          getError &&
            getError.input_name == 'terms_and_tonditions' &&
            getError.status,
        ]"
      />
    </div>
    <div class="my-4 font-wht">
      <Checkbox
        :id="'do_you_have_a_referral_code'"
        v-model="do_you_have_a_referral_code"
        :label="'I have a referral code.'"
        :input-type="'checkbox'"
        :tab-index="8"
        :name="'do_you_have_a_referral_code'"
        :is-required="false"
      />
    </div>
    <div class="my-3">
      <IInput
        v-show="do_you_have_a_referral_code"
        :id="'referral_code'"
        v-model.trim="referral_code"
        :label="'Referral code'"
        :input-type="'text'"
        :placeholder="'Enter your Referral code'"
        :tab-index="9"
        :is-required="false"
        :klass="[
          getError &&
            (getError.input_name === 'referral_code' ||
              getError.input_name === undefined) &&
            getError.status,
        ]"
        @focusout="referralCodeVerify"
      />
    </div>
    <div class="mt-4 mb-5">
      <IButton
        :action="'success'"
        type="submit"
        value="Submit"
        :tab-index="10"
        class="btn btn-primary my-4"
      >
        Continue
      </IButton>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, nextTick, ref } from 'vue'
import { useRouter } from 'vue-router'
import {
  UserRegisterDataService as User,
  type UserRegisterRequest as RegisterRequest,
  PostmarkDataService as Postmark,
  PostmarkRequest,
} from '@/services'
import {
  Input as IInput,
  Button as IButton,
  Select as ISelect,
  Checkbox,
} from '@/components'

import { useAuthTokenStore } from '@/store/authToken'
import { mapState, mapStores } from 'pinia'
import { useErrorStore } from '@/store/errorStore'
import {
  validateEmail,
  validatePassword,
  validatePasswordConfirmation,
} from '@/helpers'
import { event } from 'vue-gtag'
import { useRoute } from 'vue-router'
import { routesNames } from '@/router'

export default defineComponent({
  name: 'UserRegisterForm',
  components: {
    IInput,
    IButton,
    ISelect,
    Checkbox,
  },
  setup() {
    event('Register', { method: 'Google' })
    // Define needed instances.
    const router = useRouter()
    const route = useRoute()
    // Define needed variables.
    const email = ref(route.query?.email as string)
    const password = ref('')
    const first_name = ref('')
    const referral_code = ref(route.query?.referral_code as string)
    const last_name = ref('')
    const email_flag = ref(false)
    const containOneDigit = ref(false)
    const oneLowercaseCharacter = ref(false)
    const oneUppercaseCharacter = ref(false)
    const oneSymbol = ref(false)
    const lengthISGreaterThen = ref(false)
    const do_you_have_a_referral_code = ref(
      route.query?.referral_code !== undefined
    )
    const selected_gender = ref('')
    const password_confirmation = ref('')
    const terms_and_tonditions = ref(false)
    const canSubmitted = ref(false)
    const authTokenStore = useAuthTokenStore()

    const register = async () => {
      if (canSubmitted.value) {
        const requestData: RegisterRequest = {
          email: email.value,
          password: password.value,
          password_confirmation: password.value,
          first_name: first_name.value,
          last_name: last_name.value,
          selected_gender: selected_gender.value,
          referral_code: referral_code.value,
          settings: { not_implemented: '' },
          email_subscriptions: {
            terms_and_tonditions: terms_and_tonditions.value,
          },
        }
        // Define await function for user login.
        authTokenStore.USER_SIGNUP({
          signup_data: requestData,
        })

        authTokenStore.$subscribe(
          (
            _mutation: any,
            state: { status: number; statusText: string; message: string }
          ) => {
            if (state.status === 200) {
              useErrorStore().UPDATE_ERROR({
                message: state.message,
                status: 'success',
                reset: true,
              })
              // Send success message.
              const { redirect } = router.currentRoute.value.query
              // Checking, if redirect route is exists.
              canSubmitted.value = false
              authTokenStore.$patch(
                (state: {
                  status: number
                  statusText: string
                  message: string
                }) => {
                  state.status = 0
                  state.statusText = ''
                  state.message = ''
                }
              )

              if (redirect) {
                router.replace({ path: String(redirect) }) // 200: replace current route to saved
              } else {
                router.push({ name: routesNames.home }) // 200: push Index page
              }
            }
          }
        )
      }
    }
    return {
      email,
      password,
      first_name,
      last_name,
      selected_gender,
      password_confirmation,
      terms_and_tonditions,
      canSubmitted,
      register,
      email_flag,
      referral_code,
      do_you_have_a_referral_code,
      containOneDigit,
      oneLowercaseCharacter,
      oneUppercaseCharacter,
      oneSymbol,
      lengthISGreaterThen,
    }
  },
  data() {
    return {
      showPasswordConfirmation: false,
    }
  },
  computed: {
    ...mapState(useErrorStore, {
      getError: 'errors',
    }),
    ...mapStores(useAuthTokenStore, useErrorStore),
  },

  watch: {
    password(newValue: string) {
      const [
        showPasswordConfirmation,
        canSubmitted,
        containOneDigit,
        oneLowercaseCharacter,
        oneUppercaseCharacter,
        oneSymbol,
        lengthISGreaterThen,
      ] = validatePassword(newValue)
      this.showPasswordConfirmation = showPasswordConfirmation
      this.canSubmitted = canSubmitted
      this.containOneDigit = containOneDigit
      this.oneLowercaseCharacter = oneLowercaseCharacter
      this.oneUppercaseCharacter = oneUppercaseCharacter
      this.oneSymbol = oneSymbol
      this.lengthISGreaterThen = lengthISGreaterThen
    },
  },
  methods: {
    verifyPasswordConfirmation() {
      const [canSubmitted] = validatePasswordConfirmation(
        this.password,
        this.password_confirmation
      )
      this.canSubmitted = canSubmitted
    },
    referralCodeVerify() {
      if (this.do_you_have_a_referral_code && this.referral_code.length <= 3)
        return

      this.canSubmitted = false
      const requestData: Record<string, string> = {
        referral_code: this.referral_code,
      }

      this.authTokenStore.USER_REFERRAL_CODE_VERIFICATION({
        data: requestData,
      })

      this.authTokenStore.$subscribe(
        (
          mutation: any,
          state: { status: number; statusText: string; message: string }
        ) => {
          if (state.status === 200) {
            this.errorStore.UPDATE_ERROR({
              message: state.message,
              status: 'success',
              reset: true,
            })
            this.canSubmitted = true
            this.authTokenStore.$patch(
              (state: {
                status: number
                statusText: string
                message: string
              }) => {
                state.status = 0
                state.statusText = ''
                state.message = ''
              }
            )
          }
        }
      )
    },
    verifyEmail() {
      if (this.email.length <= 3) return
      if (validateEmail(this.email)) {
        // this.errorStore.$reset()
        this.canSubmitted = true
        this.errorStore.UPDATE_ERROR({
          message: `${this.email} 👍`,
          status: 'success',
          input_name: 'email',
          reset: true,
        })
      } else {
        this.errorStore.UPDATE_ERROR({
          error: 'Invalid Email',
          status: 'error',
          input_name: 'email',
        })
      }
    },
  },
})
</script>
