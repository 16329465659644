<template>
  <p>
    <label
      :for="id"
      class="visibility-hidden"
    >{{ label }} {{ isRequired ? '*' : '' }}</label>
  </p>
  <select
    :id="id"
    :class="['form-field']"
    :tabindex="tabIndex"
    :placeholder="placeholder"
    :value="modelValue"
    :required="isRequired"
    :aria-label="label"
    @input="$emit('update:modelValue', handleSelectChange($event))"
  >
    <option
      disabled
      value=""
    >
      {{ label }}
    </option>
    <option
      v-for="(option, index) in selectOptions"
      :key="index"
      :value="option"
    >
      {{ option }}
    </option>
  </select>
  <p
    v-if="helpText"
    class="italic text-xs mt-1"
  >
    {{ helpText }}
  </p>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ISelect',
  props: {
    id: { type: String, required: true },
    label: { type: String, required: true },
    selectOptions: { type: Array, required: true },
    tabIndex: { type: Number, required: true },
    helpText: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    modelValue: { type: String, default: '' },
    isRequired: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  setup: () => {
    // Define event handler for input change.
    const handleSelectChange = (event: Event) =>
      (event.target as HTMLSelectElement).value
    return { handleSelectChange }
  },
})
</script>
