<script setup lang="ts">
import { logoY } from '@/helpers';
import { usePortfoliosStore } from '@/store/portfolios';
import { useImage, watchOnce } from '@vueuse/core';
import 'lazysizes';
import { ref } from 'vue';

const portfoliosStore = usePortfoliosStore()

const props = defineProps({
  imageUrl: {
    type: String,
    required: true,
  },
  alt: {
    type: String,
    required: true,
  },
  portfolioId: {
    type: String,
    required: false,
    default: () => '',
  },
})

const imageOptions = ref({ src: props.imageUrl })
const { isLoading, error } = useImage(imageOptions, { delay: 1000 })

watchOnce(error, () => {
  portfoliosStore.DELETE_AND_UPLOAD_VARIANT({
    portfolio_id: props.portfolioId.toString(),
  })
})


</script>

<template>
  <div
    v-if="isLoading"
    class="is-ready-wrapper"
  >
    <div class="is-ready">
      <img
        :src="logoY"
        :srcset="logoY"
        alt="loader"
        class="loader-logo"
      >
      <div class="is-loader" />
    </div>
  </div>
  <img
    v-else
    v-lazysizes
    :src="imageUrl"
    :alt="alt"
    lazy
    class="lazyload"
    loading="lazy"
  >
</template>

<style lang="scss" scoped>
.loader-logo {
  width: unset;
  height: 30%;
}

.portfolio-gallery-multi .portfolio-gallery-container img.loader-logo {
  width: unset;
  height: 26%;
}

.is-ready-wrapper {
  min-width: 180px;
  min-height: 320px;
  display: inherit;
}

.is-ready {
  height: 15vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
  background-color: #0000;
  margin: auto;
}

.portfolio-gallery-multi .portfolio-gallery-container .is-ready {
  height: 320px;
  width: 180px;
}

.is-loader {
  width: 33px;
  margin-left: -4px;
  margin-bottom: -40%;
  aspect-ratio: 4;
  background: url('/src/assets/logo-dot.svg') 0 / calc(100% / 3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 900ms steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0);
  }
}
</style>
