import { io } from "socket.io-client";

const URL = import.meta.env.VITE_VUE_APP_YPONY_REALTIME_URL;

export const socket = io(URL, {
  autoConnect: false,
  pingTimeout: 10000,
  pingInterval: 30000,
  reconnect: true,
  heartbeatTimeout: 20000, // adjust the heartbeat timeout (in milliseconds)
  heartbeatInterval: 10000 // adjust the heartbeat interval (in milliseconds)
})
