import { authClient } from '@/requests'
import { ApiResponse, PortfolioPayloadType } from '@/types'
import { AxiosResponseHeaders } from 'axios'

/**
 * @class UserLoginDataService
 * @description Axios service provider for make API requests to UserLogin model.
 */
class UserLoginDataService {
  /**
   * @method login
   * @description Method to authenticate user by email and password.
   * @param {IUserLoginRequest} user_data
   * @returns Promise
   */
  async login(user_data: IUserLoginRequest): Promise<ApiResponse> {
    return authClient.post('/v1/users/sign_in', user_data)
  }
}

/**
 * @interface IUserLoginRequest
 * @description Public interface to describe request data for UserLogin model.
 */
export interface IUserLoginRequest {
  email: string
  password: string
}

/**
 * @interface IUserLoginResponse
 * @description Public interface to describe response data for UserLogin model.
 */
export interface IUserLoginResponse {
  status: number
  header: AxiosResponseHeaders
  data: {
    id: string
    type: string
    attributes: {
      style_id: string
      gender: string
      color: string
      style: string
      images: string[]
      plan_name: {
        name: string
        label: string
        isSelected: boolean
      }[]
      tags: string[]
      payload: PortfolioPayloadType
    }
    message: string
    status: string
    jwt: {
      access_token: string
      refresh_token: string
      expire_at: number
    }
    user: {
      id: string
      email: string
      status: number
      first_name: string
      last_name: string
      about_me: string
      account_id: string
      picture: string
      website_url: string
      abilities: string[]
      settings: { not_implemented: string }
      email_subscriptions: {
        terms_and_tonditions: string
      }
    }
  }
}

// Export UserLogin data service.
export default new UserLoginDataService()
