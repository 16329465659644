<template>
  <form class="form">
    <div class="my-3">
      <IInput
        :id="'email'"
        v-model.trim="email"
        :label="'Email'"
        :input-type="'email'"
        :placeholder="'Your email'"
        :tab-index="1"
        :is-required="true"
        :klass="[
          getError &&
            (getError.input_name === 'email' ||
              getError.input_name === undefined) &&
            getError.status,
        ]"
        @focusout="verifyEmail"
      />
    </div>
    <div class="my-3">
      <IInput
        :id="'password'"
        v-model.trim="password"
        :label="'Password'"
        :input-type="'password'"
        :placeholder="'Your password'"
        :tab-index="2"
        :is-required="true"
        :klass="[
          'password',
          getError &&
            (getError.input_name === 'password' ||
              getError.input_name === undefined) &&
            getError.status,
        ]"
      />
    </div>
    <div class="mt-4 mb-5">
      <IButton
        :is-request-loading="isLoading"
        :is-disabled="email === '' || password === ''"
        :action="'success'"
        :tab-index="3"
        class="btn btn-primary my-4"
        @click.prevent="login"
      >
        Continue
      </IButton>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { mapState, mapStores } from 'pinia'
import { useRouter } from 'vue-router'
import type { UserLoginRequest } from '@/services'
import { Button as IButton, Input as IInput } from '@/components'
import { useAuthTokenStore } from '@/store/authToken'
import { useErrorStore } from '@/store/errorStore'
import { debounceFilter, refAutoReset, watchWithFilter } from '@vueuse/core'
import { validateEmail } from '@/helpers'
import { ClockIcon } from '@heroicons/vue/outline'
import { event } from 'vue-gtag'

export default defineComponent({
  name: 'UserLoginForm',
  components: {
    IInput,
    IButton,
  },
  setup: () => {
    event('login', { method: 'Google' })
    // Define needed instances.
    const router = useRouter()
    const authTokenStore = useAuthTokenStore()
    // Define needed variables.
    const email = ref('')
    const password = ref('')
    const canSubmitted = ref(false)

    watchWithFilter(password, () => {
      if (password.value === '') {
        canSubmitted.value = false
      } else {
        canSubmitted.value = true
      }
    })

    const verifyEmail = () => {
      if (!email.value) return

      if (validateEmail(email.value)) {
        useErrorStore().$reset()
        canSubmitted.value = true
      } else {
        useErrorStore().UPDATE_ERROR({
          error: 'Invalid Email',
          status: 'error',
          input_name: 'email',
        })
      }
    }

    // Define async function for user login with email and password.
    const login = () => {
      // Define data from components.
      const requestData: UserLoginRequest = {
        email: email.value,
        password: password.value,
      }

      if (canSubmitted.value) {
        authTokenStore.USER_LOGIN({
          login_data: requestData,
        })

        authTokenStore.$subscribe(
          (
            _mutation: any,
            state: {
              status: number
              statusText: string
              message: string
              current_user: { first_name: string }
            }
          ) => {
            if (
              state.status === 200 &&
              state.statusText === 'success' &&
              state.message === 'Signed in successfully'
            ) {
              useErrorStore().UPDATE_ERROR({
                message: `Welcome back! ${state.current_user.first_name}`,
                status: 'success',
                reset: true,
              })

              // Send success message.
              const { redirect } = router.currentRoute.value.query
              // Checking, if redirect route is exists.
              canSubmitted.value = false

              authTokenStore.$patch(
                (state: {
                  status: number
                  statusText: string
                  message: string
                }) => {
                  state.status = 0
                  state.statusText = ''
                  state.message = ''
                }
              )

              if (redirect) {
                router.replace({ path: String(redirect) }) // 200: replace current route to saved
              } else {
                router.push({ name: 'home' }) // 200: push Index page
              }
            }
          }
        )
      }
    }

    // Return instances and variables.
    return {
      email,
      password,
      verifyEmail,
      login,
    }
  },
  computed: {
    ...mapState(useErrorStore, {
      getError: 'errors',
    }),
    ...mapState(useAuthTokenStore, {
      isRequestLoading: 'isRequestLoading',
      isLoading: 'isLoading',
    }),
    ...mapStores(useErrorStore),
  },
  watch: {},
})
</script>
