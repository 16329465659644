import { defineStore } from 'pinia'
import { ActionTypes } from '@/store/action-types'
import { state as initialState } from '@/store/state'
import Cookies from 'js-cookie'
import { bootstrap } from 'vue-gtag'

const cookiesStorage: Storage = {
  setItem(key, state) {
    return Cookies.set('OptanonConsent', state, {
      expires: 1,
      secure: true,
      SameSite: 'strict',
    })
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getItem(_key: string): string | null {
    if (Cookies.get('OptanonConsent')) return Cookies.get('OptanonConsent') as unknown as null
    return ''
  },
  length: 0,
  clear: (): void => {
    console.log('clear function not implemented.')
  },
  key: (_index: number): string => {
    console.log('key function not implemented.')
    return ''
  },
  removeItem: (_key: string): void => {
    console.log('removeItem function not implemented.')
  },
}

import { decodeBase64, encodeBase64 } from '../helpers/index'

import {pick} from 'lodash'
const state = pick(initialState, ['opt_in_capturing', 'user_ip'])
type optInType = {
  opt_in_capturing: boolean
}

export const useCookieBannerStore = defineStore('cookieBannerStore', {
  state: () => ({ ...state }),
  getters: {
    getOptIn({ opt_in_capturing }): optInType {
      const opt_in = cookiesStorage.getItem('opt_in_capturing') as string
      if (opt_in) {
        return decodeBase64(opt_in) as unknown as optInType
      }
      return { opt_in_capturing }
    },
  },
  actions: {
    async [ActionTypes.GET_USER_IP]() {
      this.user_ip = await fetch('https://ipinfo.io/json?token=0af5d975b2361a', { mode: 'cors' })
        .then(x => x.json())
    },
    [ActionTypes.UPDATE_OPT_IN]({ opt_in }: { opt_in: boolean }) {
      this.opt_in_capturing = opt_in
      cookiesStorage.setItem('opt_in_capturing', encodeBase64(`{ opt_in_capturing: ${opt_in} }`))
      bootstrap().then((gtag: unknown) => {
        if (gtag) {
          console.log('gtag', gtag)
        }
        this.GET_USER_IP()
      })
    },
  },
  persist: {
    storage: cookiesStorage,
    afterRestore: (ctx) => {
      console.log(`just restored '${ctx.store.$id}'`)
    },
  },
})
