import { authClient } from "@/requests";
import type { ApiResponse } from "@/types";

/**
 * @class AdminDataService
 * @description Axios service provider for make API requests to Account model.
 */
class AdminDataService {
  /**
   * @method getSubscribers
   * @description get all users.
   * @returns Promise
   */
  async getSubscribers(): Promise<ApiResponse> {
    return authClient.get("/v1/admin/subscribers");
  }
  async getPortfoliosSessions(): Promise<ApiResponse> {
    return authClient.get("/v1/admin/portfolios_sessions");
  }
  async allPortfolioStyles(accountId: string): Promise<ApiResponse> {
    return authClient.get(`/v1/admin/all_portfolio_styles/${accountId}`);
  }
  async deletePortfolio(styleId: string): Promise<ApiResponse> {
    return authClient.delete(`/v1/admin/remove_portfolio_styles/${styleId}`);
  }
  async blockAccount(accountId: string): Promise<ApiResponse> {
    return authClient.delete(`/v1/admin/block_account/${accountId}`);
  }
  async deleteAccount(accountId: string): Promise<ApiResponse> {
    return authClient.delete(`/v1/admin/delete_account/${accountId}`);
  }
  async allowPortfolio(styleId: string): Promise<ApiResponse> {
    return authClient.put("/v1/admin/allow_portfolio_styles", { styleId });
  }
  async allowPortfolios(accountId: string): Promise<ApiResponse> {
    return authClient.put("/v1/admin/allow_all_portfolio_styles", {
      accountId,
    });
  }
}

// Export Admin data service.
export default new AdminDataService();
