import { authClient } from '@/requests'
import { ApiResponse } from '@/types'

/**
 * @class TokenDataService
 * @description Axios service provider for make API requests to Token model.
 */
class TokenDataService {
  /**
   * @method renew
   * @description Method to renew JWT token.
   * @param {String} token
   * @returns Promise
   */
  renew(): Promise<ApiResponse> {
    return authClient.post('/v1/users/tokens')
  }
}

/**
 * @interface ITokenResponse
 * @description Public interface to describe response data for Token model.
 */
export interface ITokenResponse {
  data: {
    msg: string
    status: number
    jwt: {
      access_token: string
      refresh_token: string
      expire_at: number
    }
    user: {
      id: string
      email: string
      status: number
      first_name: string
      last_name: string
      user_id: number
      about_me: string
      account_id: string
      picture: string
      website_url: string
      abilities: string[]
      settings: { not_implemented: string }
      email_subscriptions: {
        terms_and_tonditions: string
      }
    }
  }
}

// Export Token data service.
export default new TokenDataService()
