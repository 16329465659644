<template>
  <main>
    <form class="form">
      <h6 class="mt-5 mb-1 text-left">
        Access & Security
      </h6>
      <div class="my-3">
        <IInput
          :id="'email'"
          v-model.trim="currentAccount().attributes.email"
          :label="'Email'"
          :input-type="'email'"
          :placeholder="'Enter your email address'"
          :tab-index="16"
          :is-required="true"
          :is-readonly="email"
        />
      </div>
      <div class="my-3">
        <IInput
          :id="'password'"
          v-model.trim="password"
          :label="'Password'"
          :input-type="'password'"
          :placeholder="'Enter password'"
          :help-text="`
            Your new password... <br>
                   <span class=${
            containOneDigit && 'password_pattern_validation'
          }> 1. must contain digit [0-9]
                   ${
            password
              ? containOneDigit
                ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
                : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
              : ''
          }<br /></span>
        <span
        class=${oneLowercaseCharacter && 'password_pattern_validation'}
          >2. One lowercase character [a-z] ${
            password
              ? oneLowercaseCharacter
                ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
                : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
              : ''
          }<br
        /></span>
        <span
        class=${oneUppercaseCharacter && 'password_pattern_validation'}
          >3. One uppercase character [A-Z] ${
            password
              ? oneUppercaseCharacter
                ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
                : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
              : ''
          }<br
        /></span>
        <span class=${
            oneSymbol && 'password_pattern_validation'
          }>4. One symbol [@#$%] ${
            password
              ? oneSymbol
                ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
                : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
              : ''
          }<br /></span>
        <span
        class=${lengthISGreaterThen && 'password_pattern_validation'}
          >5. Length of password between [5-20].${
            password
              ? lengthISGreaterThen
                ? `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'><path fill='currentColor' d='M504 256c0 136.967-111.033 248-248 248S8 392.967 8 256 119.033 8 256 8s248 111.033 248 248zM227.314 387.314l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.249-16.379-6.249-22.628 0L216 308.118l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.249 16.379 6.249 22.628.001z'></path></svg>`
                : `<svg aria-hidden='true' focusable='false' width='14' role='img' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' viewBox='0 0 122.879 122.879' ><g><path fill-rule='evenodd' clip-rule='evenodd' fill='#FF4141' d='M61.44,0c33.933,0,61.439,27.507,61.439,61.439 s-27.506,61.439-61.439,61.439C27.507,122.879,0,95.372,0,61.439S27.507,0,61.44,0L61.44,0z M73.451,39.151 c2.75-2.793,7.221-2.805,9.986-0.027c2.764,2.776,2.775,7.292,0.027,10.083L71.4,61.445l12.076,12.249 c2.729,2.77,2.689,7.257-0.08,10.022c-2.773,2.765-7.23,2.758-9.955-0.013L61.446,71.54L49.428,83.728 c-2.75,2.793-7.22,2.805-9.986,0.027c-2.763-2.776-2.776-7.293-0.027-10.084L51.48,61.434L39.403,49.185 c-2.728-2.769-2.689-7.256,0.082-10.022c2.772-2.765,7.229-2.758,9.953,0.013l11.997,12.165L73.451,39.151L73.451,39.151z'/></g></svg>`
              : ''
          }</span
        >
        `"
          :tab-index="17"
          :is-required="true"
          :klass="[
            'password',
            getError && getError.input_name == 'password' && getError.status,
          ]"
        />
      </div>
      <div
        v-if="showPasswordConfirmation"
        class="my-3 input-group"
      >
        <IInput
          :id="'password_confirmation'"
          v-model.trim="password_confirmation"
          :label="'password_confirmation'"
          :input-type="'password'"
          :placeholder="'Enter password confirmation'"
          :tab-index="18"
          :is-required="true"
          :klass="[
            'password_confirmation',
            getError &&
              getError.input_name == 'password_confirmation' &&
              getError.status,
          ]"
        />
      </div>
      <div class="mt-4 mb-5">
        <IButton
          :action="'success'"
          :tab-index="19"
          :is-disabled="!password"
          class="btn btn-primary my-4"
          @click.prevent="submit"
        >
          Update
          <span :class="isRequestLoading && 'loader'" />
        </IButton>
      </div>

      <div>
        <h6 class="mt-5 mb-1 text-left">
          Delete Account
        </h6>
        <p class="text-left">
          Do you want to leave Y.Pony and delete all your data?
        </p>

        <div class="my-4">
          <i>
            <Checkbox
              :id="'do_you_to_delete_account'"
              v-model="do_you_to_delete_account"
              :label="'Yes I want to delete my account and understand my data can not be recovered.'"
              :input-type="'checkbox'"
              :placeholder="'I agree to terms and conditions.'"
              :tab-index="7"
              :name="'do_you_to_delete_account'"
              :is-required="true"
            />
          </i>
        </div>
        <div class="mt-4 mb-5">
          <IButton
            :action="'success'"
            :tab-index="19"
            :is-disabled="!do_you_to_delete_account"
            class="btn btn-alert my-4"
            @click.prevent="cancelAccount"
          >
            Delete Account
            <span :class="isRequestLoading && 'loader'" />
          </IButton>
        </div>
      </div>
    </form>
  </main>
</template>

<script lang="ts">
import {
  Component,
  Prop,
  Vue,
  Watch,
  Ref,
  Inject,
  toNative,
} from 'vue-facing-decorator'
import {
  Input as IInput,
  Button as IButton,
  Textarea as ITextarea,
  Checkbox,
} from '@/components'
import type { AccountPasswordRequest, AccountType } from '@/types'
import { validatePassword, validatePasswordConfirmation } from '@/helpers'
import { useErrorStore } from '@/store/errorStore'
import { useResetStore } from '@/utils/useResetStore'
@Component({
  components: {
    IInput,
    IButton,
    ITextarea,
    Checkbox,
  },
  emits: ['update:handleAccount'],
  expose: ['password', 'password_confirmation'],
})
class PrivateDetails extends Vue {
  @Inject
  currentAccount!: () => AccountType

  @Prop({
    type: Boolean,
  })
  isRequestLoading?: boolean

  @Ref
  email!: string
  password!: string
  showPasswordConfirmation!: boolean
  canSubmitted!: boolean
  do_you_to_delete_account = false
  password_confirmation!: string
  containOneDigit = false
  oneLowercaseCharacter = false
  oneUppercaseCharacter = false
  oneSymbol = false
  lengthISGreaterThen = false

  @Watch('password', { immediate: true, deep: true })
  passwordWatcher(newValue: string) {
    const [
      showPasswordConfirmation,
      canSubmitted,
      containOneDigit,
      oneLowercaseCharacter,
      oneUppercaseCharacter,
      oneSymbol,
      lengthISGreaterThen,
    ] = validatePassword(newValue)
    this.showPasswordConfirmation = showPasswordConfirmation
    this.canSubmitted = canSubmitted
    this.containOneDigit = containOneDigit
    this.oneLowercaseCharacter = oneLowercaseCharacter
    this.oneUppercaseCharacter = oneUppercaseCharacter
    this.oneSymbol = oneSymbol
    this.lengthISGreaterThen = lengthISGreaterThen
  }
  @Watch('password_confirmation', { immediate: true, deep: true })
  password_confirmationWatcher(newValue: string) {
    const [canSubmitted] = validatePasswordConfirmation(
      this.password as unknown as string,
      newValue
    )
    this.canSubmitted = canSubmitted
  }

  submit() {
    const accountPasswordRequest: AccountPasswordRequest = {
      password: this.password,
      password_confirmation: this.password_confirmation,
    }
    this.$emit(
      'update:handleAccount',
      accountPasswordRequest,
      'private_account',
      this.canSubmitted
    )
  }

  cancelAccount() {
    this.$emit('update:handleAccount', '', 'cancel_account', true)
  }

  get getError() {
    return useErrorStore().getError
  }

  created() {
    useResetStore().errorStore()
  }
}

export default toNative(PrivateDetails)
</script>

<style lang="scss">
</style>
