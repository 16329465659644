import { authClient } from '@/requests'
import { ApiResponse } from '@/types'
import { AxiosResponseHeaders } from 'axios'

/**
 * @class UserRegisterDataService
 * @description Axios service provider for make API requests to UserRegister model.
 */
class UserRegisterDataService {
  /**
   * @method register
   * @description Method to register user with given name, email and password.
   * @param {IUserRegisterRequest} user_data
   * @returns Promise
   */
  register(user_data: IUserRegisterRequest): Promise<ApiResponse> {
    return authClient.post(`/v1/users/sign_up?referral_code=${user_data.referral_code}`, user_data)
  }

  referralCodeVerificarion(data: Record<string, string>): Promise<ApiResponse> {
    return authClient.post('/v1/users/verify', data)
  }
}

/**
 * @interface IUserRegisterRequest
 * @description Public interface to describe request data for UserRegister model.
 */
export interface IUserRegisterRequest {
  email: string
  password: string
  password_confirmation: string
  first_name: string
  last_name: string
  selected_gender: string
  referral_code?: string
  settings: Record<string, string>
  email_subscriptions: {
    terms_and_tonditions: boolean
  }
}
/**
 * @interface IUserRegisterResponse
 * @description Public interface to describe response data for UserLogin model.
 */
export interface IUserRegisterResponse {
  header: AxiosResponseHeaders
  data: {
    access_token: string
    refresh_token: string
    message: string
    status: string
    expire_at: number
  }
}

// Export UserRegister data service.
export default new UserRegisterDataService()
