<template>
  <p>
    <label
      :for="id"
      class="visibility-hidden"
    >{{ label }} {{ isRequired ? '*' : '' }}</label>
  </p>
  <textarea
    :id="id"
    :value="modelValue"
    :class="['form-field']"
    :tabindex="tabIndex"
    :placeholder="placeholder"
    :rows="rows || '6'"
    :required="isRequired"
    @input="$emit('update:modelValue', handleInputChange($event))"
  />
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ITextarea',
  inheritAttrs: false,
  props: {
    id: { type: String, required: true },
    label: { type: String, required: false, default: '' },
    tabIndex: { type: Number, required: true },
    rows: { type: Number, required: true },
    placeholder: { type: String, default: '' },
    modelValue: { type: [String, Array<string>], default: '' },
    isRequired: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  setup: () => {
    // Define event handler for input change.
    const handleInputChange = (event: Event) =>
      (event.target as HTMLInputElement).value
    return { handleInputChange }
  },
})
</script>
