import default_image from '@/assets/placeholder-ratio.png'
import type { State } from '@/store/state'
import type { FormDataNest, FormDataPrimitive, FormVal, PortfolioPayloadType, PortfolioType, RemoveNull } from '@/types'
import jwtDecode from 'jwt-decode'
export { default as divider } from '@/assets/divider.png'
export { default as divider2x } from '@/assets/divider@2x.png'
export { default as favicon } from '@/assets/favicon.png'
export { default as favicon2x } from '@/assets/favicon@2x.png'
export { default as logoColor2x } from '@/assets/logo-color@2x.png'
export { default as logoDot } from '@/assets/logo-dot.svg'
export { default as logoVector } from '@/assets/logo-full.svg'
export { default as logoY } from '@/assets/logo-y.svg'
export { default as default_image } from '@/assets/placeholder-ratio.png'

import { useErrorStore } from '@/store/errorStore'
// Import required actions and qualifiers.
import { reactiveComputed } from '@vueuse/core'
import { Buffer } from 'buffer/index.js'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'
import { TYPE } from 'vue-toastification'

import heic2any from 'heic2any'
// eslint-disable-next-line @typescript-eslint/no-var-requires
import _, { isNil } from 'lodash'
import getMine from './mine_types.json'

dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)
dayjs.extend(isToday)
dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)
dayjs.extend(customParseFormat)
export function createSlug(company: string | undefined): string {
  if (!company) return ''

  return company
    .toString() // Cast to string (optional)
    .normalize('NFKD') // The normalize() using NFKD method returns the Unicode Normalization Form of a given string.
    .toLowerCase() // Convert the string to lowercase letters
    .trim() // Remove whitespace from both sides of a string (optional)
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/[^\w\\-]+/g, '') // Remove all non-word chars
    .replace(/\\_/g, '-') // Replace _ with -
    .replace(/\\-\\-+/g, '-') // Replace multiple - with single -
    .replace(/\\-$/g, '') // Remove trailing -
}

export function findAndRemoveAndLastRemovedPayload(payload: PortfolioPayloadType[]) {
  const uniquePayload = _.uniqBy(payload, "portfolio_id");

  return uniquePayload
}

export function supportsVideoType(type: string) {
  // Allow user to create shortcuts, i.e. just "webm"
  const formats = new Map([
    ['ogg', 'video/ogg; codecs="theora"'],
    ['h264', 'video/mp4; codecs="avc1.42E01E"'],
    // ['mp4', 'video/mp4; codecs="avc1.42E01E"'],
    ['webm', 'video/webm; codecs="vp8, vorbis"'],
    ['vp9', 'video/webm; codecs="vp9"'],
    ['hls', 'application/x-mpegURL; codecs="avc1.42E01E"']
  ]);

  const video = document.createElement('video');
  return video.canPlayType(formats.get(type) || type);
}

export function getImageUrl(name: string, filePath: string) {
  const path = [`${filePath}`, `${name}`].filter((e) => e).join('/')

  return new URL(`../assets/${path}`, import.meta.url).pathname
}
export function buildTags(portfolio: PortfolioType["attributes"]) {
  const tags = []
  if (portfolio.tags) {
    tags.push(...portfolio.tags)
  }
  if (portfolio.account.tags) {
    tags.push(...portfolio.account.tags)
  }
  return [...tags]
}

export function toTitleCase(string: string): string {
  let i: number;
  let j: number;
  let str: string;
  const lowers = ['A', 'An', 'The', 'And', 'But', 'Or', 'For', 'Nor', 'As', 'At',
    'By', 'For', 'From', 'In', 'Into', 'Near', 'Of', 'On', 'Onto', 'To', 'With'];
  const uppers = ['Id', 'Tv'];
  const newString = string.replace(/(([a-z])(?=[A-Z][a-zA-Z])|([A-Z])(?=[A-Z][a-z]))/g, '$1 ').toLowerCase()
  str = newString.replace(/([^\W_]+[^\s-]*) */g, (txt: string) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase());

  // Certain minor words should be left lowercase unless
  // they are the first or last words in the string
  for (i = 0, j = lowers.length; i < j; i++)
    str = str.replace(new RegExp(`\\s${lowers[i]}\\s`, 'g'),
      (txt: string) => txt.toLowerCase());

  // Certain words such as initialisms or acronyms should be left uppercase
  for (i = 0, j = uppers.length; i < j; i++)
    str = str.replace(new RegExp(`\\b${uppers[i]}\\b`, 'g'),
      uppers[i].toUpperCase());

  return str;
}

export const buildAwsS3Link = (file_path: string) => {
  return `https://s3.eu-west-2.amazonaws.com/${file_path}`
}
export const encodeBase64 = (data: string) => {
  return Buffer.from(data, 'utf8').toString('base64')
}
export const decodeBase64 = (data: string) => {
  return Buffer.from(data, 'base64').toString('ascii')
}

export function slideShowImageKey(payload: PortfolioPayloadType[]) {
  const payload_filtered = payload.filter((payload) => payload.show_in_slideshow)[0]

  return payload_filtered
}

export function removeWhiteSpace(value: string): string {
  return value?.replace(/\s/g, '')
}

export function slideShowImageKeys(payload: PortfolioPayloadType[]) {
  return payload.map((payload) => payload.image_key)
}


const verifyOptions = {
  issuer: 'jwt-node',
  subject: 'jwt-node',
  audience: 'jwt-node',
  expiresIn: '1669756258',
  algorithm: ['RS256'],
}

export function getScreenSize() {
  const innerWidth = window.innerWidth
  const innerHeight = window.innerHeight
  return [innerWidth, innerHeight]
}
console.log(innerWidth, innerHeight) // for internal testing

export function verifyJWT(payload: string): State['current_user'] {
  return jwtDecode<State['current_user']>(payload)
}

const buildFormData = (formData: FormData, data: FormVal, parentKey?: string) => {
  if (Array.isArray(data)) {
    data.forEach((el) => {
      buildFormData(formData, el, parentKey)
    })

  } else if (typeof data === "object" && !(data instanceof File)) {
    Object.keys(data).forEach((key) => {
      buildFormData(formData, (data as FormDataNest)[key], parentKey ? `${parentKey}.${key}` : key)
    })

  } else {
    if (isNil(data)) {
      return
    }

    const value = typeof data === "boolean" || typeof data === "number" ? data.toString() : data
    formData.append(parentKey as string, value)
  }
}

export function appendToFormData(obj: FormDataNest | FormDataPrimitive): FormData {
  const formData = new FormData()

  buildFormData(formData, obj)

  return formData
}
export function removeEmpty<T extends object>(obj: T): RemoveNull<T> {
  return Object.fromEntries(
    Object.entries(obj)
      // trunk-ignore(eslint/@typescript-eslint/no-unused-vars)
      .filter(([_, v]) => v !== 'null')
      .filter(([_, v]) => v !== null)
      .filter(([_, v]) => v !== "undefined")

      .map(([k, v]) => [k, v === Object(v) ? removeEmpty(v) : v])
  ) as RemoveNull<T>
}

export function removeUndefinedValuesFromObject(obj: any): Record<string, any> {
  const newObj = {}
  // biome-ignore lint/complexity/noForEach: <explanation>
  Object.keys(obj).forEach((key) => {
    if (obj[key] === Object(obj[key])) newObj[key] = removeUndefinedValuesFromObject(obj[key])
    else if (
      obj[key] !== 'undefined' ||
      obj[key] !== undefined ||
      obj[key] === 'null' ||
      obj[key] === null ||
      obj[key] !== 'undefined/undefined'
    ) {
      newObj[key] = obj[key]
    }
  })

  return newObj
}

export function price_range(price: string) {
  return price.split('/')
}

export function toJSON(params: string | boolean | Record<string, any>) {
  // biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
  let new_params
  if (typeof params == 'object') {
    new_params = JSON.parse(JSON.stringify(params))
  } else if (typeof params === 'string' || typeof params === 'boolean') {
    new_params = !!(params === 'true' || params === true)
  }
  return new_params
}

export function dateLocale(val: Date) {
  return dayjs(val).toLocaleString()
}

export function dateOnly(val: Date) {
  // 'DD MMMM YYYY'
  return dayjs(val).locale('de').format('DD MMMM, YYYY')
}

export function dateTime(val: Date) {
  return dayjs(val).locale('de').format('DD MMMM, YYYY HH:mm:ss')
}

export function dateTimeShort(val: Date) {
  return dayjs(val).locale('de').format('DD MMM, YYYY HH:mm')
}

export function dateShort(val: Date) {
  return dayjs(val).locale('de').format('DD MMM YYYY')
}

export function weekDayShort(val: Date) {
  return dayjs(val).locale('de').format('D dddd HH:mm a')
}

export function isExpireAtLessThenNow(expire_at: any): boolean {
  const now = new Date() // standard now time in milliseconds
  const expire_time = new Date(expire_at * 1000 - 60000) // subtract 1 minute from expire
  return expire_time <= now && '_y_pony' in localStorage
}

export const mapN = <T = any[]>(count: number, fn: (...args: any[]) => T): T[] => {
  if (count === -1) return []
  return [...Array(count)].map((_, i) => fn())
}

export function makeid(length: number): string {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export async function preProcessImageFile(file: File, emit: ((arg0: string, arg1: any) => void)) {
  let blob = null as unknown as Blob
  let fileToUpload = null

  if (getExtension(file).type !== 'img') {
    return false
  }

  blob = file

  // If is not of type HEIC we skip the file
  if (getExtension(file).fileExt === "image/heic" || getExtension(file).fileExt === "image/heif") {
    // Let's turn the HEIC image into JPEG so the browser can read it
    emit('isConverting', true)
    blob = await heic2any({
      blob: file,
      toType: 'image/jpeg',
      quality: 0.85,
    });
    fileToUpload = new File([new Blob([blob])], file.name, {
      type: "image/jpeg",
    });
  }
  emit('isConverting', false)

  // The editor expects a File so let's convert our Blob
  return { preview: URL.createObjectURL(blob), fileToUpload: fileToUpload || blob }
}

export function showInSlideshow(portfolio: PortfolioType) {
  return portfolio.attributes.payload.filter((payload) => {
    return toJSON(payload.show_in_slideshow) === true
  })
}
export function filterHiddenPortfolio(portfolio: PortfolioType): PortfolioPayloadType[] {
  return portfolio.attributes.payload.filter((payload) => {
    return toJSON(payload.hidden_in_portfolio) === false
  })
}
export function renderImages(count: number, forSlideShow = false) {
  const render_image = mapN(count, () => ({
    id: makeid(10),
    show_in_gallery: false,
    image_to_delete: false,
    hidden_in_portfolio: false,
    show_in_slideshow: forSlideShow,
    preview: default_image,
    image_key: undefined,
    file: undefined,
    name: 'simple image',
    cover: '',
    filename: 'simple image',
    published: false
  }))
  return render_image
}
// export function getExtension(url: string |) {
//   let fileExt = '';
//   if (typeof url === 'object') {
//     fileExt = url.file
//   }

//   if (!url || typeof url === 'object') {
//     console.log('url', url)
//     return
//   }
//   const types = new Map([
//     ['png', 'img'],
//     // ['webp', 'img'],
//     ['jpg', 'img'],
//     ['gif', 'img'],
//     ['mp4', 'video'],
//   ])

//   const newURL = new URL(url)
//   const extension = newURL.pathname.split('.')[1]
//   const type = types.get(extension)
//   return { type, extension }
// }

export function getExtension(url: string | File) {
  const types = new Map([
    ['image/png', 'img'],
    ['png', 'img'],
    ['image/webp', 'img'],
    ['webp', 'img'],
    ['image/jpg', 'img'],
    ['image/jpeg', 'img'],
    ['jpg', 'img'],
    ['jpeg', 'img'],
    ['video/mp4', 'video'],
    ['video/m4v', 'video'],
    ['video/x-m4v', 'video'],
    ['video/webm', 'video'],
    ['webm', 'video'],
    ['mpg4', 'video'],
    ['mp4', 'video'],
    ['m4v', 'video'],
    ['video/quicktime', 'video'],
    ['mov', 'video'],
    ['video/x-sgi-movie', 'video'],
    ['image/jpeg', 'img'],
    ['jpeg', 'img'],
    ["image/heic", 'img'],
    ["heic", 'img'],
  ])
  let fileExt = ''
  const getMineType = new Map(getMine as unknown as readonly [])

  if (typeof url === 'string') {
    const newURL = url.toLowerCase().split('.')
    fileExt = newURL.pop() as string
  }


  if (url && typeof url === 'object') {
    fileExt = url.type
  }

  const type = types.get(fileExt)
  const getType = getMineType.get(fileExt)

  return { type, fileExt, mineType: getType }
}

export function log(name: 'logger', s: never) {
  console.log(name, s)
}
export function generateTabIndex(input: number) {
  return Math.floor(Math.random() * input)
}

export function isAdmin(abilities: string[]) {
  return abilities.includes('admin')
}

export function getIDFromString(params: string): string {
  return params.split('-').pop() as string
}
export function waitFor(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
export function mapStatus(name: string | number): TYPE {
  // or "success", "error", "default", "info" and "warning"
  let status = TYPE.DEFAULT
  switch (name) {
    case 'error' || 422 || 401:
      status = TYPE.ERROR
      break
    case 'success' || 200 || 201:
      status = TYPE.SUCCESS
      break
    case 'warning':
      status = TYPE.WARNING
      break
    case 'info':
      status = TYPE.INFO
      break
    default:
      status = TYPE.DEFAULT
  }
  return status
}

export function validatePasswordConfirmation(password: string, password_confirmation: string) {
  let canSubmitted = false
  if (password === password_confirmation) {
    useErrorStore().UPDATE_ERROR({
      message: 'Password and Password confirmation are matching',
      status: 'success',
      reset: true,
    })
    canSubmitted = true
  } else {
    if (password_confirmation.length >= 8) {
      useErrorStore().UPDATE_ERROR({
        message: 'Password and Password confirmation are not matching',
        status: 'error',
        input_name: 'password_confirmation',
        reset: true,
      })
    }
  }
  return [canSubmitted]
}

export function validatePassword(password: string) {
  let showPasswordConfirmation = false
  let canSubmitted = false
  let containOneDigit = false
  let oneLowercaseCharacter = false
  let oneUppercaseCharacter = false
  let oneSymbol = false
  let lengthISGreaterThen = false
  const regex_symbols = /[-!$%^&?@#]/

  if (/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-!$%^&?@#]).{5,20}/.test(password)) {
    useErrorStore().UPDATE_ERROR({
      message: 'Password pattern match!',
      status: 'success',
      input_name: 'password_confirmation',
      reset: true,
    })
    showPasswordConfirmation = true
    canSubmitted = true
  } else {
    if (password && password.length >= 8) {
      useErrorStore().UPDATE_ERROR({
        message: 'Invalid Password',
        status: 'error',
        input_name: 'password',
        reset: true,
      })
    }
  }
  if (password) {
    if (/(?=.*\d)/.test(password)) containOneDigit = true

    if (/(?=.*[a-z])/.test(password)) oneLowercaseCharacter = true

    if (/(?=.*[A-Z])/.test(password)) oneUppercaseCharacter = true

    if (regex_symbols.test(password)) oneSymbol = true

    if (/.{5,20}/.test(password)) lengthISGreaterThen = true
  }

  return [
    showPasswordConfirmation,
    canSubmitted,
    containOneDigit,
    oneLowercaseCharacter,
    oneUppercaseCharacter,
    oneSymbol,
    lengthISGreaterThen,
  ]
}

// export function isNumber(value: unknown) {
//   return typeof value === 'number'
// }
// export function pluck(arr: Record<string, any> | undefined, key: string): string[] {
//   if (arr === undefined) return []

//   const propertyValues = Object.values(arr)
//   return _.without(
//     _.compact(propertyValues.map((i) => i[key])).map(_.startCase),
//     'Undefined'
//   ) as string[]
// }
export function checker([v, ...vs]: string[], a: string[]): boolean {
  return v !== void 0 ? a.includes(v) && checker(vs, a) : true
}
export function validateEmail(email: string) {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
}

export function avatarSizeCheck(file: File) {
  const sizeInMB = (file.size / (1024 * 1024)).toFixed(2)

  if (Number(sizeInMB) > 10 && getExtension(file).type === 'img') {
    useErrorStore().UPDATE_ERROR({
      message: `File too big (> 20MB) or File type not supported : ${`${sizeInMB}MB`} `,
      status: 'info',
      reset: true,
    })
    return false
  }
}

export function fileSizeCheck(file: File) {
  const sizeInMB = (file.size / (1024 * 1024)).toFixed(2)
  if (Number(sizeInMB) > 25 && getExtension(file).type === 'video') {
    useErrorStore().UPDATE_ERROR({
      message: `File too big (> 25MB) or File type not supported : ${`${sizeInMB}MB`} `,
      status: 'info',
      reset: true,
    })
    return false
  }
  if (Number(sizeInMB) > 20 && getExtension(file).type === 'img') {
    useErrorStore().UPDATE_ERROR({
      message: `File too big (> 20MB) or File type not supported : ${`${sizeInMB}MB`} `,
      status: 'info',
      reset: true,
    })
    return false
  }
  if (!getExtension(file).type) {
    useErrorStore().UPDATE_ERROR({
      message: `We not supported this type of file or video : ${getExtension(file).fileExt
        }, only these type of file are supported : png, webp, jpg, mp4, m4v, mov`,
      status: 'info',
    })

    return false
  }
}

export function fileNameExist(file: File, portfolioPayload: PortfolioPayloadType[]) {
  const fileExist =
    portfolioPayload.filter((payload) => {
      if (!payload.file) return false

      return (
        (payload.file && (payload.file as unknown as File).name === file.name) ||
        payload.filename === file.name
      )
    }).length === 1

  if (fileExist) {
    useErrorStore().UPDATE_ERROR({
      message: 'Cannot upload file with the same name',
      status: 'info',
    })
  }

  return fileExist
}

const state = reactiveComputed(() => ({
  type: 'alert',
  active: false,
  message: '',
  title: '',
  okText: 'Ok',
  cancelText: 'Cancel',
  inputType: 'text',
  html: false,
}))

// -----------------------------------
// Private Methods
// -----------------------------------
let close: (arg0: boolean) => void // will hold our promise resolve function
const dialogPromise = () => new Promise((resolve) => (close = resolve))
const open = (message: string) => {
  state.message = message
  state.active = true
  return dialogPromise()
}
const reset = () => {
  state.active = false
  state.message = ''
  state.okText = 'Ok'
  state.cancelText = 'Cancel'
  state.inputType = 'text'
  state.html = false
  state.title = ''
  state.type = 'alert'
}

// -----------------------------------
// Public interface
// -----------------------------------

export const dialog = {
  get state() {
    return state
  },
  title(title: string) {
    state.title = title
    return this
  },
  okText(text: string) {
    state.okText = text
    return this
  },

  cancelText(text: string) {
    state.cancelText = text
    return this
  },
  inputType(type: string) {
    state.inputType = type
    return this
  },
  html(enabled = true) {
    state.html = enabled
    return this
  },
  alert(message: any) {
    state.type = 'alert'
    return open(message)
  },
  confirm(message: any) {
    state.type = 'confirm'
    return open(message)
  },
  prompt(message: any) {
    state.type = 'prompt'
    return open(message)
  },
  cancel() {
    close(false)
    reset()
  },
  ok(input = true) {
    input = state.type === 'prompt' ? input : true
    close(input)
    reset()
  },
}

// export dialog
export const isObjectEmpty = (objectName: any) => {
  return Object.values(objectName).length !== 0
}
