<template>
  <main class="admin">
    <div
      v-if="accountStore.isRequestLoading"
      class="center"
    >
      <orbit-spinner
        :animation-duration="1500"
        :size="64"
        :color="'#F99FB5'"
      />
    </div>
    <div v-else>
      <div class="d-flex flex-column flex-md-row justify-space-between column-gap-3">
        <div class="profile-gallery mb-2">
          <ul class="single-image">
            <li class="shadow">
              <img
                v-if="current_account.attributes.avatar"
                :src="current_account.attributes.avatar"
              >
              <img
                v-else
                src="@/assets/placeholder-ratio_1-1.png"
              >
            </li>
          </ul>
        </div>

        <div class="flex-grow">
          <h5 class="mt-2 mb-1">
            {{ current_account.attributes.fullname }}
          </h5>
          <p class="mt-1 mb-0">
            <i>{{ current_account.attributes.job_title }}</i>
          </p>
          <div class="font-orange">
            <i>
              <p>
                <i>
                  {{ current_account.attributes.about_me }}
                </i>
              </p>
              <ul>
                <li>
                  <i>
                    <strong>Price range:</strong>
                    {{ current_account.attributes.price_min }}-{{
                      current_account.attributes.price_max
                    }}
                    €
                  </i>
                </li>
              </ul>
              <p class="mb-0">
                <i><strong>Please contact me here:</strong></i>
              </p>
              <div class="d-flex grid-gap-2 my-2">
                <a
                  v-if="current_account.attributes.whatsapp"
                  :href="'tel:' + current_account.attributes.whatsapp"
                  class="btn btn-primary m-0"
                >
                  Whatsapp
                </a>
                <a
                  v-if="current_account.attributes.telegram"
                  :href="current_account.attributes.telegram"
                  class="btn btn-primary m-0"
                >
                  Telegram
                </a>
              </div>
            </i>
          </div>
        </div>

        <div class="d-flex flex-column grid-gap-3 my-3">
          <router-link
            v-if="current_account.attributes.company"
            :to="{
              query: {
                account_id: current_account.attributes.account_id,
              },
              path: `/${createSlug(current_account.attributes.company)}/me`,
            }"
            class="btn btn-tertiary m-0"
          >
            View Profile
          </router-link>
          <button
            class="btn btn-tertiary m-0"
            @click.prevent="
              deleteAccount(current_account.attributes.account_id)
            "
          >
            Block
          </button>
          <button
            class="btn btn-alert m-0"
            @click.prevent="blockAccount(current_account.attributes.account_id)"
          >
            Delete
          </button>
        </div>
      </div>

      <div>
        <h6 class="mb-3">
          Portfolio
        </h6>
        <div
          v-if="!adminStore.isLoading"
          class="portfolio-wrapper"
        >
          <div
            v-for="portfolio in adminStore.portfolios"
            :key="portfolio.id"
            class="portfolio-entry"
          >
            <div class="portfolio-gallery-view">
              <ul>
                <template v-if="portfolio.attributes.payload.length === 2">
                  <li class="shadow box">
                    <c-video
                      v-if="
                        portfolio.attributes.payload.length &&
                          gExtension(portfolio.attributes.payload[0].filename)
                            .type === 'video'
                      "
                      class="slide-img"
                      :payload="portfolio.attributes.payload"
                    />
                  </li>
                </template>

                <template v-else>
                  <li class="shadow box">
                    <c-image
                      v-if="
                        portfolio.attributes.payload.length &&
                          gExtension(portfolio.attributes.payload[0].filename)
                            .type === 'img'
                      "
                      :image-url="
                        portfolio.attributes.payload[0].slideshow_16x9
                      "
                      :alt="portfolio.attributes.payload[0].filename"
                      :portfolio-id="portfolio.id?.toString()"
                    />
                    <c-video
                      v-else-if="
                        portfolio.attributes.payload.length &&
                          gExtension(portfolio.attributes.payload[0].filename)
                            .type === 'video'
                      "
                      class="slide-img"
                      :payload="portfolio.attributes.payload"
                    />
                  </li>
                </template>
              </ul>
            </div>

            <div
              v-if="!portfolio.attributes.is_approved"
              class="d-flex flex-align-center justify-content-center column-gap-3 mb-5"
            >
              <img
                src="/src/assets/btn-approve.png"
                srcset="/src/assets/btn-approve@2x.png 2x"
                alt="Accept image"
                @click.prevent="allowPortfolio(portfolio.id)"
              >

              <img
                src="/src/assets/btn-delete.png"
                srcset="/src/assets/btn-delete@2x.png 2x"
                alt="Previous Page"
                @click.prevent="deletePortfolio(portfolio.id)"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { CImage, CVideo } from '@/components'
import { createSlug, dialog, getExtension, toJSON } from '@/helpers'
import { routesNames } from '@/router'
import { useAccountStore, useSelectTabStore } from '@/store/accounts'
import { useAdminStore } from '@/store/admin'
import type { AccountType } from '@/types'
import { OrbitSpinner } from 'epic-spinners'
import { Component, Inject, Prop, toNative, Vue, Watch } from 'vue-facing-decorator'
import { useRoute } from 'vue-router'

@Component({
    components: {
        OrbitSpinner,
        CImage,
        CVideo,
    },
})
class SubscriberInformation extends Vue {
    accountStore = useAccountStore()
    adminStore = useAdminStore()
    route = useRoute()
    routesNames = routesNames
    confirmed = false
    selectTabStore = useSelectTabStore()

    @Prop({
      type: String
    })
    fullname!: string

    @Inject
    currentAccount!: () => AccountType

    get current_account() {
        return this.accountStore.current_account
    }
    get portfolios() {
        return this.adminStore.currentAccountPortfolios
    }
    createSlug(company: string) {
        return createSlug(company)
    }
    gExtension(file: string) {
        return getExtension(file)
    }
    toJSON(to: string | boolean) {
        return toJSON(to)
    }

    @Watch('fullname', { immediate: true, deep: true })
    updatePageTitle(name: string) {
        if (name) {
            document.title = `Subscriber Information - ${name}`
        }
    }

    async allowPortfolio(style_id: string) {
        this.confirmed = (await dialog
            .okText('Yes')
            .cancelText('No')
            .confirm('Are you sure?')) as boolean

        if (this.confirmed) {
            this.adminStore.ALLOW_PORTFOLIO({ style_id })
        }
    }
    async deletePortfolio(style_id: string) {
        this.confirmed = (await dialog
            .okText('Yes')
            .cancelText('No')
            .confirm('Are you sure?')) as boolean

        if (this.confirmed) {
            this.adminStore.DELETE_PORTFOLIO({ style_id })
            this.$emit('goToProfile', this.current_account.attributes.account_id)
        }
    }
    async deleteAccount(account_id: string) {
        this.confirmed = (await dialog
            .okText('Yes')
            .cancelText('No')
            .confirm('Are you sure?')) as boolean

        if (this.confirmed) {
            this.adminStore.DELETE_ACCOUNT({ account_id })
            this.selectTabStore.updateSelectedSubTab(0)
        }
    }
    async blockAccount(account_id: string) {
        this.confirmed = (await dialog
            .okText('Yes')
            .cancelText('No')
            .confirm('Are you sure?')) as boolean

        if (this.confirmed) {
            this.adminStore.BLOCK_ACCOUNT({ account_id })

            this.selectTabStore.updateSelectedSubTab(0)
        }
    }

    created() {
        if (this.route.query.account_id) {
            this.accountStore.GET_CURRENT_ACCOUNT({
                account_id: this.route.query.account_id as string,
            })
            this.adminStore.GET_CURRENT_ACCOUNT_PORTFOLIOS({
                account_id: this.route.query.account_id as string,
            })
        }
    }
}

export default toNative(SubscriberInformation)
</script>

<style lang="scss">
.admin .portfolio-gallery-view ul {
    justify-content: flex-start;
    li {
        overflow: hidden;
        height: calc(15vw + 2.5rem);
        width: 15%;
        min-width: 15vh;
        position: relative;
        justify-content: center;
        span {
            position: absolute;
            bottom: -3em;
            gap: 0.5em;
        }
    }
}
</style>
