<template>
  <main
    v-show="false"
    class="admin"
  >
    <h1>Subscriber <span class="text-nowrap">> John Doe</span></h1>

    <div
      class="d-flex flex-column flex-md-row justify-space-between column-gap-3"
    >
      <div class="profile-gallery mb-2">
        <ul class="single-image">
          <li class="shadow">
            <img
              src="/src/assets/cards/img-profile-4.jpg"
              alt=""
            >
          </li>
        </ul>
      </div>

      <div class="flex-grow">
        <h5 class="mt-2 mb-1">
          {{ currentAccount().attributes.fullname }}
        </h5>
        <p class="mt-1 mb-0">
          <i>Job Title</i>
        </p>
        <div class="font-orange">
          <i>
            <p>
              <i>Hi, I am Adam. I have over 10 years working experience. I am
                the right stylist, if you like fancy haircuts.</i>
            </p>
            <ul>
              <li>
                <i><strong>Price range:</strong> 30-100 €</i>
              </li>
              <li>
                <i><strong>Location:</strong> I visit you</i>
              </li>
            </ul>
            <p class="mb-0">
              <i><strong>Please contact me here:</strong></i>
            </p>
            <div class="d-flex grid-gap-2 my-2">
              <button class="btn btn-primary m-0">Whatsapp</button>
              <button class="btn btn-primary m-0">Telegram</button>
            </div>
          </i>
        </div>
      </div>

      <div class="d-flex flex-column grid-gap-3 my-3">
        <button class="btn btn-tertiary m-0">
          View Profile
        </button>
        <button class="btn btn-tertiary m-0">
          Block
        </button>
        <button class="btn btn-alert m-0">
          Delete
        </button>
      </div>
    </div>

    <div>
      <h6 class="mb-3">
        Portfolio
      </h6>
      <div class="portfolio-wrapper">
        <div class="portfolio-entry">
          <div class="portfolio-gallery-view">
            <ul>
              <li class="shadow">
                <img
                  src=""
                  alt="image of portfolio"
                >
                <span class="d-flex column-gap-3">
                  <img
                    src="/src/assets/btn-approve.png"
                    srcset="/src/assets/btn-approve@2x.png 2x"
                    alt="Accept image"
                  >
                  <img
                    src="/src/assets/btn-edit.png"
                    srcset="/src/assets/btn-edit@2x.png 2x"
                    alt="Edit image"
                  >
                  <img
                    src="/src/assets/btn-deny.png"
                    srcset="/src/assets/btn-deny@2x.png 2x"
                    alt="Deny Image"
                  >
                </span>
              </li>

              <li class="shadow">
                <img
                  src=""
                  alt="image of portfolio"
                >
                <span class="d-flex column-gap-3">
                  <img
                    src="/src/assets/btn-edit.png"
                    srcset="/src/assets/btn-edit@2x.png 2x"
                    alt="Edit image"
                  >
                </span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import type { AccountType } from '@/types'
import { Component, Vue, Inject, toNative } from 'vue-facing-decorator'

@Component
class Customers extends Vue {
  @Inject
  currentAccount!: () => AccountType
}

export default toNative(Customers)
</script>

<style lang="scss">
.admin .portfolio-gallery-view ul {
  justify-content: flex-start;

  li {
    overflow: hidden;
    height: calc(15vw + 2.5rem);
    width: 15%;
    min-width: 15vh;
    position: relative;
    justify-content: center;

    span {
      position: absolute;
      bottom: -3em;
      gap: 0.5em;
    }
  }
}
</style>
