<template>
  <main class="text-left">
    <div class="my-3">
      <h6 class="mb-2">
        Your referral balance
      </h6>
      <div class="membership-status card card--primary shadow text-center">
        <i>
          Your membership is<br>
          <span>{{ currentAccount().attributes.abilities.toString() }}</span>
        </i>
      </div>
    </div>

    <div class="my-3">
      <h6 class="mb-2">
        How it works
      </h6>
      <ul class="list-vertical gap-xs bullets">
        <li>1 referral: get 1 month GOLD status.</li>
        <li>3 referrals: get GOLD status forever.</li>
        <li>9 referrals: get DIAMOND status.*</li>
      </ul>
    </div>

    <div class="my-3">
      <h6 class="mb-2">
        Your referral balance
      </h6>
      <ProgressBar
        :value="currentAccount().attributes.invited_user_count * 10"
        :show-value="true"
        color="#38b2ac"
      />
      <p class="">
        Your referred
        <strong>{{ currentAccount().attributes.invited_user_count }} friend{{
          currentAccount().attributes.invited_user_count > 2 ? 's' : ''
        }}</strong>
        {{
          currentAccount().attributes.invited_user_count > 3
            ? 'so far. Great! You earned 3 months of GOLD status.'
            : ''
        }}
      </p>
    </div>

    <div class="my-3">
      <h6 class="mb-2">
        Referred friends
      </h6>
      <p>
        Your friends need to be active members and have to use the slideshow to
        make your referral successful.
      </p>

      <div
        v-if="referralUsers()?.data?.length"
        class="table-responsive"
      >
        <table>
          <tr>
            <th class="email">
              Email
            </th>
            <th class="status">
              Status
            </th>
            <th class="action" />
          </tr>

          <tr
            v-for="user in referralUsers().data"
            :key="user.id"
          >
            <td class="email">
              {{ user.attributes.referee_email }}
            </td>
            <td
              :class="[user.attributes.referee_status.toLowerCase(), 'status']"
            >
              {{ user.attributes.referee_status }}
            </td>
            <td class="action">
              <IButton
                v-if="user.attributes.referee_status === 'PENDING'"
                class="btn-icon"
                :tab-index="3"
                @click.prevent="deleteInviteUser(user.attributes.referee_email)"
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="-3 0 32 32"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  xmlns:sketch="http://www.bohemiancoding.com/sketch/ns"
                  fill="#000000"
                >
                  <g
                    id="SVGRepo_bgCarrier"
                    stroke-width="0"
                  />
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <title>trash</title>
                    <desc>Created with Sketch Beta.</desc>
                    <defs />
                    <g
                      id="Page-1"
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                      sketch:type="MSPage"
                    >
                      <g
                        id="Icon-Set-Filled"
                        sketch:type="MSLayerGroup"
                        transform="translate(-261.000000, -205.000000)"
                        fill="#000000"
                      >
                        <path
                          id="trash"
                          d="M268,220 C268,219.448 268.448,219 269,219 C269.552,219 270,219.448 270,220 L270,232 C270,232.553 269.552,233 269,233 C268.448,233 268,232.553 268,232 L268,220 L268,220 Z M273,220 C273,219.448 273.448,219 274,219 C274.552,219 275,219.448 275,220 L275,232 C275,232.553 274.552,233 274,233 C273.448,233 273,232.553 273,232 L273,220 L273,220 Z M278,220 C278,219.448 278.448,219 279,219 C279.552,219 280,219.448 280,220 L280,232 C280,232.553 279.552,233 279,233 C278.448,233 278,232.553 278,232 L278,220 L278,220 Z M263,233 C263,235.209 264.791,237 267,237 L281,237 C283.209,237 285,235.209 285,233 L285,217 L263,217 L263,233 L263,233 Z M277,209 L271,209 L271,208 C271,207.447 271.448,207 272,207 L276,207 C276.552,207 277,207.447 277,208 L277,209 L277,209 Z M285,209 L279,209 L279,207 C279,205.896 278.104,205 277,205 L271,205 C269.896,205 269,205.896 269,207 L269,209 L263,209 C261.896,209 261,209.896 261,211 L261,213 C261,214.104 261.895,214.999 262.999,215 L285.002,215 C286.105,214.999 287,214.104 287,213 L287,211 C287,209.896 286.104,209 285,209 L285,209 Z"
                          sketch:type="MSShapeGroup"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </IButton>
              <div v-if="user.attributes.referee_status === 'ACCEPTED'">
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="SVGRepo_bgCarrier"
                    stroke-width="0"
                  />
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M4 12.6111L8.92308 17.5L20 6.5"
                      stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <div v-else-if="user.attributes.referee_status === 'ACTIVE'">
                <svg
                  width="20px"
                  height="20px"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    id="SVGRepo_bgCarrier"
                    stroke-width="0"
                  />
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M4 14L9 19L20 8M6 8.88889L9.07692 12L16 5"
                      stroke="#00cc00"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                </svg>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <form class="form">
      <div class="my-3">
        <IInput
          :id="'email'"
          v-model.trim="email"
          :label="'Email'"
          :input-type="'email'"
          :placeholder="'Add new email'"
          :tab-index="1"
          :is-required="true"
          :klass="[
            getError && getError.input_name === 'email' && getError.status,
          ]"
          @mousedown="resetStore.errorStore()"
        />
      </div>
      <div class="mb-4 mt-3">
        <IButton
          :action="'success'"
          :tab-index="2"
          class="btn btn-primary my-0"
          @click.prevent="inviteUser"
        >
          Send
          <span :class="isLoading && 'loader'" />
        </IButton>
      </div>
    </form>

    <div class="mt-3 mb-5 text-center">
      <div class="mb-4">
        <small> or share your referral link: </small>
      </div>
      <div
        ref="referralLink"
        class="text-muted text-center mt-1 mb-2 card"
      >
        {{ buildReferralLink }}
      </div>
      <button
        type="button"
        class="btn btn-text"
        @click.prevent="copyReferralLink()"
      >
        Copy Link
      </button>
    </div>
  </main>
</template>

<script lang="ts">
import {
  Component,
  Vue,
  Inject,
  Emit,
  Prop,
  Ref,
  Watch,
  toNative,
} from 'vue-facing-decorator'
import type { AccountType, ReferralUsers } from '@/types'

import {
  Input as IInput,
  Button as IButton,
  Textarea as ITextarea,
  ProgressBar,
} from '@/components'
import { useErrorStore } from '@/store/errorStore'
import { validateEmail } from '../helpers/index'
import { useResetStore } from '@/utils/useResetStore'

@Component({
  components: { IInput, IButton, ITextarea, ProgressBar },
  emits: ['update:inviteUser'],
})
class Referrals extends Vue {
  @Inject
  currentAccount!: () => AccountType

  @Inject
  referralUsers!: () => ReferralUsers

  @Prop({
    type: Boolean,
    required: true,
  })
  isLoading!: boolean

  email = ''
  errorStore = useErrorStore()
  resetStore = useResetStore()
  @Ref
  referralLink!: HTMLParagraphElement

  copyReferralLink() {
    navigator.clipboard.writeText(this.referralLink.innerHTML)
  }

  countActiveReferralUser(users: ReferralUsers) {
    let count = 0
    let active_user_count = 0
    if (users?.data) {
      for (const user of users.data) {
        if (user.attributes.referee_status === 'ACTIVE') {
          count += 10
          active_user_count++
        }
      }
    }

    return { count, active_user_count }
  }

  @Emit
  deleteInviteUser(email: string) {
    return { email: email }
  }

  @Emit
  inviteUser() {
    if (validateEmail(this.email)) {
      this.resetStore.errorStore()
      return { email: this.email }
    }
    this.errorStore.UPDATE_ERROR({
      message: 'Invalid Email',
      status: 'error',
      input_name: 'email',
    })
  }

  @Watch('isLoading')
  emailInputCleaner() {
    this.email = ''
  }

  get buildReferralLink() {
    return `${import.meta.env.VITE_VUE_APP_URL}/register?referral_code=${
      this.currentAccount().attributes?.referral_code
    }`
  }

  get getError() {
    return this.errorStore.getError
  }
}

export default toNative(Referrals)
</script>

<style lang="scss">
.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #000;
}

.separator:not(:empty)::before {
  margin-right: 0.25em;
}

.separator:not(:empty)::after {
  margin-left: 0.25em;
}

.membership-status {
  font-weight: 300;
  line-height: 1.5;

  span {
    font-size: 1.25em;
    font-weight: 700;
    text-transform: uppercase;
  }
}
</style>
