
<template>
  <div class="input-group">
    <p>
      <label
        :for="id"
        class="visibility-hidden"
      >{{ label }} {{ isRequired ? '*' : '' }}</label>
    </p>
    <div class="input-group">
      <input
        v-if="showPassword && inputType === 'password'"
        :id="id"
        v-model="InputModel"
        :class="['form-field', klass]"
        type="text"
        :tabindex="tabIndex"
        :placeholder="placeholder"
        :required="isRequired"
      >
      <input
        v-else
        :id="id"
        v-model="InputModel"
        :class="['form-field', klass]"
        :type="inputType"
        :tabindex="tabIndex"
        :placeholder="placeholder"
        :required="isRequired"
        :readonly="isReadonly"
      >
      <div
        v-if="inputType === 'password'"
        class="input-icon-container"
        @click="toggleShow"
      >
        <EyeIcon v-if="showPassword" />
        <EyeOffIcon v-else />
      </div>
    </div>
    <small>
      <p
        v-if="helpText"
        class="mt-2 mx-2 align-left font-black"
        v-html="helpText"
      />
    </small>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { EyeIcon, EyeOffIcon } from '@heroicons/vue/outline'

export default defineComponent({
  name: 'IInput',
  components: {
    EyeIcon,
    EyeOffIcon,
  },

  props: {
    id: { type: String, required: true },
    label: { type: String, required: true },
    klass: { type: Array, default: () => [''] },
    inputType: { type: String, required: true },
    tabIndex: { type: Number, required: true },
    helpText: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    modelValue: { type: [String, Boolean, Number], default: '' },
    isRequired: { type: Boolean, default: false },
    isReadonly: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    // showPassword: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  setup: () => {
    // Define event handler for input change.
    const handleInputChange = (event: Event) =>
      (event.target as HTMLInputElement).value
    return { handleInputChange }
  },
  data() {
    return {
      showPassword: false,
    }
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? 'Hide' : 'Show'
    },

    InputModel: {
      get() {
        return this.modelValue
      },
      set(newChecked: unknown) {
        this.$emit('update:modelValue', newChecked)
      },
    },
  },
  methods: {
    toggleShow() {
      this.showPassword = !this.showPassword
    },
  },
})
</script>

<style lang="sass" scoped>
</style>
