<template>
  <div>
    <main>
      <h1>
        <span @click="updateSelectedSubTab(0)">Analytics </span>
        <span
          v-if="selectedSubTab === 1"
          class="text-nowrap"
        >
          > {{ currentAccount().attributes.fullname }}
        </span>
      </h1>

      <TabGroup :selected-index="selectedSubTab">
        <TabPanels class="mt-5">
          <TabPanel>
            <data-table
              :items="analytics()"
              :fields="fields"
            >
              <template #cell(status)="{ value }">
                <span class="icon-container">
                  <div :class="value === 'online' ? 'status-online' : 'status-offline' " />
                </span>
              </template>
              <template #profile-link="{ item }">
                <img
                  src="@/assets/btn-next.png"
                  srcset="@/assets/btn-next@2x.png 2x"
                  alt="View Profile"
                  @click="goToProfile(item.account_id)"
                >
              </template>
            </data-table>
          </TabPanel>
          <TabPanel><SubscriberInformation :fullname="currentAccount().attributes.fullname" /></TabPanel>
        </TabPanels>
      </TabGroup>
    </main>
  </div>
</template>

<script lang="ts">
import { DataTable } from '@/components'
import { dateShort, weekDayShort } from '@/helpers'
import type { AccountType } from '@/types'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/vue'
import { computed } from 'vue'
import { Component, Emit, Inject, Vue, Watch, toNative } from 'vue-facing-decorator'
import { useSelectTabStore } from '../store/accounts/selectTabStore'
import SubscriberInformation from './SubscriberInformation.vue'

@Component({
  components: {
    DataTable,
    SubscriberInformation,
    TabGroup,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
  },
})
class Subscribers extends Vue {
  @Inject
  currentAccount!: () => AccountType
  @Inject
  analytics!: () => void
  createAt(date: Date) {
    return dateShort(date)
  }

  selectedSubTab = computed(
    () => useSelectTabStore().getSelectedSubTab
  ) as unknown as number

  @Emit
  goToProfile(account_id: string) {
    return { account_id }
  }

  @Emit
  updateSelectedSubTab(index: number) {
    return index
  }

  lastSigning(date: Date) {
    if (date) {
      return weekDayShort(date)
    }
  }

  @Watch('$route.name', { immediate: true, deep: true })
  updatePageTitle( name: string ){
    if (name === 'admin') {
      document.title = `Admin - Analytics`
    }
  }


  fields = [
    { key: 'status', label: 'status' },
    { key: 'first_name', label: 'First name' },
    { key: 'last_name', label: 'Last name' },
    { key: 'city', label: 'Location' },
    { key: 'runtime_last_month', label: 'Runtime last month', sortable: true },
    { key: 'runtime_total', label: 'Runtime total', sortable: true  },
  ]
}
export default toNative(Subscribers)
</script>

<style scoped>
.icon-container {
  width: 10px;
  height: 10px;

}
.status-online {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: green;
  border: 2px solid green;
}
.status-offline {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: rgb(240, 14, 14);
  border: 2px solid rgb(240, 14, 14);


}
</style>
